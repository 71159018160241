import {
  Box,
  Flex,
  FormControl,
  HStack,
  Highlight,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  useOutsideClick,
} from '@chakra-ui/react';
import { useEffect, useMemo, useRef, useState } from 'react';

import type { Maybe, OptionItem } from '@/common/types';
import useTranslation from '@/utils/i18n/useTranslation';
import { useScreenInfos } from '@/utils/mobiles/useScreenInfos';
import {
  MdExpandMore as ArrowDownIcon,
  MdExpandLess as ArrowUpIcon,
  MdClose as CloseIcon,
  MdSearch as SearchIcon,
} from 'react-icons/md';
import ClosableModal from './ClosableModal';
import { MultipleLayerCheckboxOptionWrapper } from './MultipleLayerCheckbox';

export type ValueType = Maybe<number | string>;
export type CustomSearchableSelectOption = {
  value: ValueType;
  label: string;
  isChecked?: boolean;
};

type CommonProps = {
  isClearable?: boolean;
  isDisabled?: boolean;
  placeholder?: string;
  label?: string;
  options: CustomSearchableSelectOption[];
};

export type CustomSearchableSelectProps =
  | ({
      isMulti: true;
      value: CustomSearchableSelectOption[];
      onChange: (options: CustomSearchableSelectOption[]) => void;
    } & CommonProps)
  | ({
      isMulti?: false;
      value: CustomSearchableSelectOption;
      onChange: (option?: CustomSearchableSelectOption) => void;
    } & CommonProps);

const CustomSearchableSelect = (props: CustomSearchableSelectProps) => {
  const { t } = useTranslation();
  const {
    placeholder,
    options: defaultOptions,
    value: defaultValue,
    isClearable,
    isDisabled,
    onChange,
    label,
    isMulti,
  } = props;

  const desktopOverlayRef = useRef<HTMLDivElement>(null);

  const checkedOptions = useMemo(() => {
    if (isMulti) {
      return defaultValue;
    } else {
      return defaultValue ? [defaultValue] : [];
    }
  }, [defaultValue, isMulti]);

  const options = useMemo(() => {
    const options = defaultOptions.map((option) => {
      const isChecked = checkedOptions.some(
        (checkedOption) => checkedOption.value === option.value
      );
      return {
        ...option,
        isChecked,
      };
    });

    return options;
  }, [checkedOptions, defaultOptions]);

  const [isShowSelect, setIsShowSelect] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [filteredOptions, setFilteredOptions] = useState<CustomSearchableSelectOption[]>(options);
  const [inputValue, setInputValue] = useState<string>('');

  const matchedOptions = useMemo(() => {
    const matchedOptions = filteredOptions.filter((option) =>
      option.label.toLowerCase().includes(searchText.toLowerCase())
    );
    return searchText ? matchedOptions : options;
  }, [filteredOptions, options, searchText]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: why is this options necessary?
  useEffect(() => {
    // 初期値を入れるため
    if (!isMulti) {
      setInputValue(defaultValue ? defaultValue.label : '');
    }
  }, [defaultValue, isMulti, options]);

  const handleSearch = (input: string) => {
    if (!isShowSelect) setIsShowSelect(true);

    setSearchText(input);
    if (input === '') {
      if (!isMulti) onChange();
      return;
    }
    const searchResult = options.filter((option) =>
      option.label.toLowerCase().includes(input.toLowerCase())
    );

    setFilteredOptions(searchResult);
  };

  const handleClear = () => {
    if (isShowSelect) {
      setSearchText('');
    }

    setInputValue('');
    isMulti ? onChange([]) : onChange();
  };

  const handleSelect = (option: CustomSearchableSelectOption) => {
    if (option.isChecked) {
      handleDeselect(option);
      return;
    }

    if (isMulti) {
      const newOptions = options.filter((opt) => opt.value !== option.value);
      setFilteredOptions(newOptions);
      // remove duplicates
      const selectedOptions = [
        ...new Map([...defaultValue, option].map((option) => [option.value, option])).values(),
      ];
      onChange(selectedOptions);
      setIsShowSelect(true);
    } else {
      onChange(option);
      setInputValue(option.label);
      setIsShowSelect(false);
    }
  };

  const handleDeselect = (option: CustomSearchableSelectOption) => {
    if (isMulti) {
      const newOptions = defaultValue.filter((opt) => opt.value !== option.value);
      onChange(newOptions);
      setFilteredOptions([...options, option]);
    }
  };

  const toggleShow = (isShow: boolean) => {
    setIsShowSelect(isShow);
    setSearchText('');
  };

  const convertToItem = (options: CustomSearchableSelectOption[]) => {
    return options.map((option) => {
      return {
        id: option.value,
        label: option.label,
        isChecked: option.isChecked,
      } as OptionItem;
    });
  };

  const { isMobile, isDesktop } = useScreenInfos();

  useOutsideClick({
    ref: desktopOverlayRef,
    handler: () => isDesktop && toggleShow(false),
  });

  return (
    <Box pos='relative'>
      <HStack>
        <FormControl>
          <InputGroup>
            <Box
              py={1}
              pr='5rem'
              w='100%'
              minH='2.5rem'
              rounded='md'
              sx={{
                border: '1px solid #ebebeb',
              }}
            >
              {isMulti ? (
                <Box minW='100%' pos='relative' zIndex={100}>
                  <Box
                    pos='absolute'
                    inset={0}
                    w='100%'
                    h='100%'
                    onClick={() => toggleShow(!isShowSelect)}
                  />
                  <Box display='inline-block'>
                    {defaultValue.length > 0 ? (
                      defaultValue.map((option, index) => (
                        <SelectedOptionTag key={index} option={option} onRemove={handleDeselect} />
                      ))
                    ) : (
                      <>
                        {isMobile && (
                          <Text my={1} mx={2} color='neutral.500'>
                            {placeholder}
                          </Text>
                        )}
                      </>
                    )}
                    {isDesktop && (
                      <Input
                        variant='unstyled'
                        m={1}
                        placeholder={defaultValue.length > 0 ? '' : t('actions.search')}
                        onClick={() => setIsShowSelect(true)}
                        value={searchText}
                        onChange={(e) => handleSearch(e.target.value)}
                        _placeholder={{ color: 'neutral.500' }}
                        sx={{
                          m: 1,
                          ml: 2,
                          w: 'auto',
                          display: 'inline-grid',
                          gridTemplateColumns: 'auto auto',
                        }}
                      />
                    )}
                  </Box>
                </Box>
              ) : (
                <>
                  {inputValue && !isShowSelect ? (
                    <Input
                      key={isShowSelect ? 'search' : 'selected'}
                      pr='5rem'
                      variant='unstyled'
                      m={1}
                      pl={2}
                      value={inputValue}
                      onClick={() => setIsShowSelect(true)}
                      readOnly
                      _placeholder={{ color: 'neutral.500' }}
                      placeholder={placeholder}
                      isDisabled={isDisabled}
                    />
                  ) : (
                    <Input
                      pr='5rem'
                      variant='unstyled'
                      m={1}
                      pl={2}
                      defaultValue={searchText}
                      onClick={() => setIsShowSelect(true)}
                      onChange={(e) => handleSearch(e.target.value)}
                      _placeholder={{ color: 'neutral.500' }}
                      placeholder={placeholder}
                      isDisabled={isDisabled}
                    />
                  )}
                </>
              )}
            </Box>

            <InputRightElement width='5rem' justifyContent='end' h='100%'>
              <HStack spacing={0} py={0} h='100%'>
                {isClearable && <ClearButton onClick={handleClear} />}

                <IconButton
                  fontSize={24}
                  variant='unstyled'
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                  borderLeft='1px'
                  borderColor='neutral.300'
                  borderRadius='none'
                  height='100%'
                  aria-label='Select options'
                  color={isShowSelect ? 'primary.500' : 'neutral.500'}
                  icon={isShowSelect ? <ArrowUpIcon /> : <ArrowDownIcon />}
                  onClick={() => (isShowSelect ? toggleShow(false) : setIsShowSelect(true))}
                />
              </HStack>
            </InputRightElement>
          </InputGroup>
        </FormControl>
      </HStack>
      {isDesktop && (
        <Popover isOpen={isShowSelect} matchWidth autoFocus={false}>
          <PopoverTrigger>
            <Box position='absolute' top={0} height={10} w='full' pointerEvents='none' />
          </PopoverTrigger>
          <PopoverContent w='full' maxH={300} overflowY='auto' ref={desktopOverlayRef}>
            <PopoverBody>
              {isShowSelect && (
                <OptionsContainer
                  options={matchedOptions}
                  searchText={searchText}
                  handleSelect={handleSelect}
                />
              )}
            </PopoverBody>
          </PopoverContent>
        </Popover>
      )}
      {isMobile && (
        <ClosableModal
          isOpen={isShowSelect}
          onClose={() => toggleShow(false)}
          size={{ base: 'full', md: 'lg' }}
          scrollBehavior='inside'
        >
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton
              zIndex={100}
              data-testid='custom-searchable-select-modal-close-button'
            />
            <ModalBody p={1} borderTop='1px' borderColor='neutral.300'>
              <Box>
                <Flex>
                  <Text mx='auto' fontWeight={700} my={3}>
                    {label}
                  </Text>
                </Flex>
                <HStack px={2}>
                  <InputGroup>
                    <InputLeftElement pointerEvents='none' h='100%'>
                      <SearchIcon color='neutral.500' />
                    </InputLeftElement>
                    <Box
                      pl={8}
                      py={1}
                      pr='5rem'
                      w='100%'
                      minH='2.5rem'
                      rounded='md'
                      sx={{
                        border: '1px solid #ebebeb',
                      }}
                    >
                      <Box display='inline-block'>
                        {isMulti &&
                          defaultValue.length > 0 &&
                          defaultValue.map((option, index) => (
                            <SelectedOptionTag
                              key={index}
                              option={option}
                              onRemove={handleDeselect}
                            />
                          ))}
                        <Input
                          variant='unstyled'
                          m={2}
                          display='inline-grid'
                          value={searchText}
                          onClick={() => setIsShowSelect(true)}
                          onChange={(e) => handleSearch(e.target.value)}
                          _placeholder={{ color: 'neutral.500' }}
                          placeholder={
                            isMulti && defaultValue.length > 0 ? t('actions.search') : placeholder
                          }
                          isDisabled={isDisabled}
                        />
                      </Box>
                    </Box>
                    <InputRightElement width='5rem' h='100%' justifyContent='end'>
                      <HStack spacing={0} py={0}>
                        {isClearable && isMulti && <ClearButton onClick={handleClear} />}
                      </HStack>
                    </InputRightElement>
                  </InputGroup>
                </HStack>
                <Box
                  bg='neutral.0'
                  mt={1}
                  py={2}
                  rounded='md'
                  border='1px'
                  borderColor={{
                    base: 'transparent',
                    md: 'neutral.200',
                  }}
                >
                  {isShowSelect &&
                    (isMulti ? (
                      <MultipleLayerCheckboxOptionWrapper
                        parentOptionsMap={{}}
                        searchText={searchText}
                        matchedOptions={convertToItem(matchedOptions)}
                        checkedOptions={convertToItem(matchedOptions)}
                        handleCheck={({ targetOption }) =>
                          handleSelect({
                            value: targetOption.id,
                            label: targetOption.label,
                            isChecked: targetOption.isChecked,
                          })
                        }
                      />
                    ) : (
                      <OptionsContainer
                        options={matchedOptions}
                        searchText={searchText}
                        handleSelect={handleSelect}
                      />
                    ))}
                </Box>
              </Box>
            </ModalBody>
          </ModalContent>
        </ClosableModal>
      )}
    </Box>
  );
};

type OptionsContainerProps = {
  options: CustomSearchableSelectOption[];
  searchText: string;
  handleSelect: (option: CustomSearchableSelectOption) => void;
};

const OptionsContainer = (props: OptionsContainerProps) => {
  const { options, searchText, handleSelect } = props;
  const { t_errors } = useTranslation();

  return (
    <Stack width='full' spacing='0px'>
      {options.length > 0 ? (
        <>
          {options.map((option) => (
            <Box
              key={option.value}
              width='full'
              textAlign='left'
              px={4}
              py={2}
              rounded='none'
              color={option.isChecked ? 'primary.600' : ''}
              bg={option.isChecked ? 'primary.100' : ''}
              _hover={{
                bg: 'neutral.100',
              }}
              _focus={{
                bg: 'neutral.100',
              }}
              onClick={() => {
                handleSelect(option);
              }}
            >
              <Text>
                <Highlight query={searchText} styles={{ color: 'primary.600' }}>
                  {option.label}
                </Highlight>
              </Text>
            </Box>
          ))}
        </>
      ) : (
        <Text px={5}>{t_errors('not-found')}</Text>
      )}
    </Stack>
  );
};

type ClearButtonProps = {
  onClick: () => void;
};

const ClearButton = (props: ClearButtonProps) => {
  return (
    <IconButton
      as='div'
      fontSize={16}
      variant='unstyled'
      display='flex'
      justifyContent='center'
      alignItems='center'
      aria-label='Clear'
      color='neutral.500'
      icon={<CloseIcon />}
      _hover={{
        bg: 'neutral.100',
        cursor: 'pointer',
      }}
      onClick={props.onClick}
    />
  );
};

type SelectedOptionTagProps = {
  option: CustomSearchableSelectOption;
  onRemove: (option: CustomSearchableSelectOption) => void;
};

const SelectedOptionTag = (props: SelectedOptionTagProps) => {
  const { option, onRemove } = props;
  return (
    <Tag
      m={1}
      display='inline-grid'
      gridTemplateColumns='auto auto'
      onClick={() => onRemove(option)}
      _hover={{
        cursor: 'pointer',
      }}
    >
      <TagLabel>
        <Text>{option.label}</Text>
      </TagLabel>
      <TagCloseButton />
    </Tag>
  );
};

export default CustomSearchableSelect;
